@media (max-width: 1239px) {
  footer {
    .copyrights,
    .footer-btm a {
      font-size: 1rem !important;
    }

    .copyrights {
      margin-right: 15px !important;
    }

    .footer-btm li {
      padding: 0 8px !important;
    }
  }
}

@media (max-width: 375px) {
  .privacy-page .home .home-content p {
    font-size: 1.12rem;
  }
  .privacy-page .overview .overview-item-box.item-one .overview-item-title,
  .privacy-page .overview .overview-item-box.item-five .overview-item-title {
    font-size: 1.5rem !important;
  }

  .privacy-page .overview .overview-item-box .overview-item-title {
    width: 100% !important;
  }

  .privacy-page .our-products .products-boxs .product-item .product-details {
    padding: 1rem;
  }

  .privacy-page .our-products .products-boxs .product-item .product-details h3 {
    font-size: 1.4rem !important;
  }

  .privacy-page .overview .overview-boxs .overview-item-box .item-descrip p {
    font-size: 1.05rem;
  }

  .privacy-page .your-control .control-boxs .control-box .control-box-title {
    width: 100%;
  }

  .todo-list-page .features .feature-item.item-three .image,
  .application-page .features .features-box .feature-item.item-six .image {
    bottom: 66%;
    top: auto;
  }

  .todo-list-page .features .features-box .feature-item,
  .application-page .features .features-box .feature-item {
    min-height: 585px;
  }

  .todo-list-page .features .feature-item.item-two .item-header,
  .todo-list-page .features .feature-item.item-three .item-header {
    width: 75%;
  }

  .todo-list-page .features .feature-item.item-three .image,
  .application-page .features .features-box .feature-item.item-six .image {
    right: -13%;
    bottom: 70% !important;
  }

  .todo-list-page .features .feature-item.item-three img,
  .application-page .features .features-box .feature-item.item-six .image img {
    right: -57% !important;
  }

  .application-page .features .features-box .feature-item.item-five {
    min-height: 475px !important;
  }

  .home {
    h1.title {
      font-size: 2.9rem !important;
    }

    h2.subtitle {
      font-size: 1.5rem !important;
    }
  }

  .our-commitment h2 {
    font-size: 2.25rem !important;
  }

  .privacy-page {
    .home {
      .home-content .image {
        max-width: 110px !important;
        margin-right: 12px !important;
      }
    }
  }

  .todo-list-page .features .feature-item.item-two .item-header {
    width: 76%;
  }

  .application-page .features .features-box .feature-item h4 {
    width: 100%;
    font-size: 1.55rem;
  }

  .application-page .features .feature-item.item-five .item-header,
  .application-page .features .feature-item.item-six .item-header {
    width: 80%;
  }

  .application-page .features .feature-item.item-five .image {
    left: -30px !important;
  }

  .section-links {
    z-index: 1;
    font-size: 1rem !important;

    a:not(:last-child) {
      margin-right: 5px;
    }
  }

  .our-commitment h2 {
    font-size: 2.25rem !important;
  }

  footer {
    .reference-link {
      .title,
      .icon {
        font-size: 1rem !important;
      }

      ul {
        li {
          a {
            font-size: 0.95rem !important;
          }
        }
      }
    }

    .footer-btm {
      a,
      .copyrights {
        font-size: 0.9rem !important;
      }
    }
  }
}

@media (max-width: 500px) {
  .privacy-page {
    br {
      display: none;
    }
    .overview {
      .overview-item-box.item-two br {
        display: block;
      }

      .overview-boxs {
        .overview-item-box.item-one,
        .overview-item-box.item-five {
          .overview-item-title {
            width: 95%;
            font-size: 1.85rem;
            margin: 0 auto 25px;
          }
        }
      }

      .overview-item-box.item-five .links {
        font-size: 1rem;
        margin-bottom: 0 !important;
      }
    }
  }

  .privacy-page .overview .overview-title h2,
  .privacy-page .our-products .our-products-title h2.title,
  .privacy-page .your-control .your-control-title h2.title,
  .privacy-page .view-details .view-details-title h2.title,
  .todo-list-page .overview .video-container .overview-title h2.title,
  .todo-list-page .invite-section h2 {
    font-size: 2.35rem !important;
  }

  .privacy-page .overview .overview-title p.subtitle,
  .privacy-page .our-products .our-products-title p.subtitle,
  .privacy-page .your-control .your-control-title p.subtitle,
  .privacy-page .view-details .view-details-title p.subtitle,
  .todo-list-page .overview .video-container .overview-title p.subtitle {
    font-size: 1.25rem;
  }

  .overview-boxs .overview-item-box,
  .control-boxs .control-box,
  .todo-list-page .features .features-box .feature-item,
  .application-page .features .features-box .feature-item {
    padding: 2rem 1rem !important;
  }

  .todo-list-page .home .home-content h1.title,
  .application-page .home .home-content h1.title {
    font-size: 3.55rem;
  }

  .application-page .features .features-box .feature-item h4,
  .todo-list-page .features .features-box .feature-item h4 {
    width: 100%;
  }

  .application-page .features .feature-item.item-five .image img {
    transform: scale(1.3) translate(0px, 40px);
  }

  .application-page .features .feature-item.item-five .item-header {
    bottom: 30px;
  }

  .application-page .overview .video-container .overview-title {
    top: 0;
  }

  .application-page .features .feature-item.item-five .item-header,
  .application-page .features .feature-item.item-six .item-header {
    width: 80%;
  }

  .application-page .main-feature .feature-box .features-list,
  .todo-list-page .main-feature .feature-box .features-list {
    width: 85%;
  }

  .todo-list-page .main-feature .feature-box h2 {
    font-size: 2rem;
  }

  .todo-list-page .features .features-box .feature-item .item-content,
  .application-page .features .features-box .feature-item .item-content {
    width: 85%;
  }

  .todo-list-page .invite-section h3,
  .application-page .invite-section h3 {
    width: 100%;
    font-size: 1.25rem;
  }

  .todo-list-page .features .feature-item.item-two .item-header,
  .todo-list-page .features .feature-item.item-three .item-header {
    width: 77%;
  }

  .todo-list-page .features .feature-item.item-three .image,
  .application-page .features .features-box .feature-item.item-six .image {
    bottom: 66%;
    left: 0;
    right: 0;
  }

  .todo-list-page .features .feature-item.item-three img,
  .application-page .features .features-box .feature-item.item-six .image img {
    right: -60%;
  }

  .application-page .overview .video-container .overview-title {
    width: 100% !important;
  }

  .application-page .features .features-box .feature-item.item-five {
    min-height: 620px;
  }

  .application-page .features .features-box .feature-item.item-six {
    min-height: 750px;
  }

  .section-links {
    z-index: 1;
    font-size: 1rem;

    a:not(:last-child) {
      margin-right: 5px;
    }
  }

  footer {
    .reference-link {
      .title,
      .icon {
        font-size: 1.05rem;
      }

      ul {
        li {
          a {
            font-size: 1rem;
          }
        }
      }
    }

    .footer-btm {
      a,
      .copyrights {
        font-size: 0.9rem !important;
      }
    }
  }
}

@media (max-width: 768px) {
  .privacy-page {
    .home .home-content,
    .overview .overview-title,
    .overview .overview-boxs .overview-item-box .item-descrip p,
    .our-products .our-products-title,
    .your-control .your-control-title,
    .view-details .view-details-title p.subtitle {
      width: 100%;
    }
    .overview .overview-boxs .overview-item-box {
      min-height: auto;

      .image img {
        padding: 1rem;
      }
    }
    .overview-boxs {
      .image-container {
        margin-bottom: 35px;
      }
      .overview-item-box.item-one {
        .overview-item-title {
          margin: 0 auto 25px !important;
        }
      }
    }
  }

  .privacy-page .our-products .products-boxs .product-item .columns {
    display: flex;
    flex-direction: column-reverse;
  }

  .privacy-page .our-products .products-boxs .app-bot-poster-container,
  .privacy-page .products-boxs .todo-list-bot-poster-container,
  .privacy-page .products-boxs .premium-subscriptions-poster-container {
    display: none;
  }

  .privacy-page .product-item .app-bot-details-container,
  .privacy-page .product-item .todo-list-bot-details-container,
  .privacy-page .product-item .premium-subscriptions-details-container {
    border-radius: 18px !important;
  }

  .privacy-page .product-item .title-phone {
    margin-bottom: 0 !important;
    margin-top: 35px;
  }

  .privacy-page .our-products .products-boxs .product-poster h3 {
    margin-top: 10px;
    font-size: 2rem;
  }

  .privacy-page .our-products .products-boxs .product-poster .image {
    max-width: 180px;
    margin: auto;
  }

  .privacy-page .your-control {
    padding-top: 0;
  }

  .privacy-page .your-control .control-boxs .control-box {
    min-height: auto;
  }

  .application-page .features .features-box .feature-item.item-four .image img,
  .application-page .features .features-box .feature-item.item-one .image img {
    margin-bottom: 15px;
    margin-top: 15px;
  }

  .todo-list-page .overview .video-container .overview-title .title,
  .application-page .overview .video-container .overview-title h2.title {
    width: 85% !important;
  }

  .todo-list-page .overview .video-container .overview-title p.subtitle,
  .application-page .overview .video-container .overview-title p.subtitle {
    width: 100% !important;
  }

  .application-page .invite-section,
  .todo-list-page .invite-section {
    padding: 0rem 1.5rem 3rem;
  }

  .privacy-page .view-details {
    padding: 3rem 1.5rem;
  }

  .application-page .notice p,
  .todo-list-page .notice p {
    width: 100%;
  }

  .application-page .features .feature-item.item-five .item-header,
  .application-page .features .feature-item.item-six .item-header {
    width: 75%;
  }

  .todo-list-page .main-feature .feature-box .image {
    margin: auto;
  }

  .todo-list-page .features .features-box .feature-item.item-four {
    min-height: auto;
  }

  .todo-list-page .features .features-box .feature-item.item-one {
    padding: 4rem 1rem 1rem !important;
  }

  .application-page .main-feature .feature-box h2 {
    font-size: 1.85rem;
  }

  .application-page .features .features-box .feature-item.item-four {
    min-height: auto !important;
  }

  .todo-list-page .features .features-box .feature-item.item-four .image img,
  .application-page .features .features-box .feature-item.item-one .image img {
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .application-page .features .features-box .feature-item.item-one,
  .application-page .features .features-box .feature-item.item-two,
  .application-page .features .features-box .feature-item.item-three,
  .todo-list-page .features .features-box .feature-item.item-one {
    min-height: auto !important;
  }

  .todo-list-page .features .features-box .feature-item.item-one {
    padding: 2rem !important;
  }

  .application-page .features .feature-item.item-six,
  .application-page .features .feature-item.item-five {
    min-height: 835px;
  }

  footer {
    .reference-link {
      padding-bottom: 0;
      border: 0;
      display: block;

      .title {
        padding: 7px 0;
        border-bottom: 1px solid #c7c7c7;
      }

      div {
        margin-right: 0;
      }

      div:not(:last-child) {
        margin-bottom: 10px;
      }

      ul {
        padding-left: 15px;
      }
    }

    .footer-btm {
      display: flex;
      flex-direction: column-reverse;

      .pages-links {
        margin-bottom: 10px;
        text-align: center;
        justify-content: center;
      }

      .copyrights {
        margin: 0;
        text-align: center;
      }
    }
  }
}

@media (max-width: 500px) {
  .todo-list-page .overview .video-container .overview-title .title,
  .todo-list-page .overview .video-container .overview-title p.subtitle,
  .application-page .overview .video-container .overview-title .title,
  .application-page .overview .video-container .overview-title p.subtitle {
    width: 100% !important;
  }
}
