.privacy-page {
  .home {
    position: relative;
    background: url('../img/background-1.png') no-repeat center center;
    background-size: cover;
    min-height: 85vh;

    .hero-body {
      align-items: center;
      display: flex;
    }
    .home-content {
      width: 66%;
      .is-flex {
        margin-bottom: 35px;
        align-items: flex-end;
        .image {
          max-width: 120px;
          margin-right: 25px;
        }
        h1.title {
          font-size: 3rem;
          font-weight: 500;
          color: #fff;
        }
      }
      p {
        color: #fff;
        line-height: 1.75;
        font-size: 1.25rem;
      }
    }
  }

  .overview {
    padding: 4.5rem 1.5rem 3rem;
    .overview-title {
      width: 47%;
      margin: auto;
      h2.title {
        color: #fff;
        font-weight: bold;
        text-align: center;
        font-size: 3rem;
      }

      p.subtitle {
        font-size: 1.5rem;
        line-height: 1.5;
        color: #fff;
        text-align: center;
        margin-bottom: 45px;
      }
    }

    .overview-boxs {
      .overview-item-box {
        background-color: #141414;
        border-radius: 18px;
        padding: 2rem;
        min-height: 590px;

        .overview-item-title {
          text-align: center;
          color: #fff;
          font-weight: bold;
          width: 100%;
          font-size: 1.5rem;
          min-height: 100px;
          margin: auto;
        }

        .image {
          padding: 1rem;
          img {
            padding: 1rem;
            max-width: 320px;
            margin: auto;
          }
        }

        .item-descrip {
          h5 {
            text-align: center;
            font-weight: bold;
            color: #fff;
            font-size: 1.25rem;
          }
          p {
            text-align: center;
            color: #fff;
            font-size: 1.2rem;
            width: 80%;
            margin: auto;
          }
        }
      }
      .overview-item-box.item-one,
      .overview-item-box.item-five {
        min-height: auto;
        .overview-item-title {
          width: 85%;
          font-size: 2rem;
          margin: 0 auto 70px;
        }

        .image {
          img {
            max-width: 500px;
          }
        }
      }

      .overview-item-box.item-five {
        .overview-item-title {
          margin: 0 auto 35px;
        }

        .links {
          text-align: center;
          color: $white;
          margin-bottom: 0;
          padding-bottom: 35px;

          span {
            a {
              margin-right: 4px;
            }
          }

          a {
            color: $white;
            margin-left: 4px;

            strong {
              color: #6772e5;

              &:hover {
                color: #6772e5;
              }
            }

            &:not(:last-child) {
              margin-right: 7px;
            }

            &:hover {
              color: $white;
            }
          }
        }
      }
    }
  }
  .our-products {
    .our-products-title {
      width: 47%;
      margin: auto;
      h2.title {
        color: #fff;
        font-weight: bold;
        text-align: center;
        font-size: 3rem;
      }

      p.subtitle {
        font-size: 1.5rem;
        line-height: 1.5;
        color: #fff;
        text-align: center;
        margin-bottom: 45px;
      }
    }

    .products-boxs {
      .product-item {
        margin-bottom: 35px;

        .app-bot-details-container {
          background-image: linear-gradient(
            to bottom,
            #f15151 0%,
            #c9946c 100%
          );
          border-radius: 18px 0 0 18px;
        }

        .todo-list-bot-details-container {
          background-image: linear-gradient(
            to bottom,
            #4baf9e 0%,
            #188cab 100%
          );
          border-radius: 18px 0 0 18px;
        }

        .premium-subscriptions-details-container {
          background-image: linear-gradient(
            to bottom,
            #f5b31f 0%,
            #e88716 100%
          );
          border-radius: 18px 0 0 18px;
        }

        .product-details {
          padding: 2rem;

          .image {
            max-width: 128px;
            margin: 0 auto 10px;
          }

          h3 {
            text-align: center;
            font-size: 2rem;
            color: $white;
            margin-bottom: 40px;
          }

          h4 {
            color: $white;
            font-weight: 500;
            font-size: 1.35rem;
            margin-bottom: 15px;
          }

          .privacy-accordion-faq {
            .privacy-accordion-menu-title {
              margin-bottom: 15px;
              padding-bottom: 10px;
              border-bottom: 1px solid rgba($white, 0.5);
              h5 {
                position: relative;
                color: #fff;
                font-size: 1.25rem;
                font-weight: normal;

                .icon {
                  position: absolute;
                  left: auto;
                  right: 0;
                  i {
                    transition: all 0.3s ease-in-out;
                  }
                }

                .opened {
                  transform: rotate(180deg);
                }
              }
            }

            .privacy-accordion-menu-content {
              display: none;
              p {
                color: #fff;
                font-size: 1.12rem;
                margin-top: 15px;
                margin-bottom: 30px;
              }
            }
          }
        }
      }

      .app-bot-poster-container {
        position: relative;

        border-radius: 0 18px 18px 0;
        background-image: linear-gradient(217deg, #f15151 0%, #ec4f4f 100%);
      }

      .todo-list-bot-poster-container {
        position: relative;

        border-radius: 0 18px 18px 0;
        background-image: linear-gradient(46deg, #028cab 0%, #018dad 100%);
      }

      .premium-subscriptions-poster-container {
        position: relative;
        border-radius: 0 18px 18px 0;
        background-image: linear-gradient(216deg, #f1a829 0%, #f0a21b 100%);
      }

      .product-poster {
        padding: 1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        flex-direction: column;
        h3 {
          color: $white;
          text-align: center;
          font-weight: bold;
          font-size: 2.75rem;
          margin-top: 25px;
          margin-bottom: 25px;
        }

        .image {
          max-width: 200px;
          position: relative;
          margin-left: auto;
        }
      }
    }
  }

  .your-control {
    padding-bottom: 1rem;
    .your-control-title {
      width: 47%;
      margin: auto;
      h2.title {
        color: #fff;
        font-weight: bold;
        text-align: center;
        font-size: 3rem;
      }

      p.subtitle {
        font-size: 1.5rem;
        line-height: 1.5;
        color: #fff;
        text-align: center;
        margin-bottom: 45px;
      }
    }

    .control-boxs {
      border-bottom: 1px solid rgba($white, 0.49);
      .control-box {
        background-color: #141414;
        border-radius: 18px;
        padding: 2rem 3.5rem;
        min-height: 600px;

        .control-box-title {
          text-align: center;
          color: #fff;
          font-weight: bold;
          width: 100%;
          margin: auto;
          font-size: 1.5rem;
          min-height: 100px;
        }

        .image {
          padding: 1rem;
          img {
            padding: 1rem;
            max-width: 320px;
            margin: 0 auto 15px;
          }
        }

        .item-descrip {
          color: #fff;
          text-align: center;
          font-size: 1.05rem;
        }
      }
    }
    .notice {
      font-size: 0.85rem;
      color: #8e8e8e;
      margin-top: 45px;
      text-align: center;
      margin-bottom: 15px;
    }
  }

  .view-details {
    padding: 5rem 1.5rem;

    .view-details-title {
      h2.title {
        color: #fff;
        font-weight: bold;
        text-align: center;
        font-size: 3rem;
      }

      p.subtitle {
        font-size: 1.5rem;
        line-height: 1.5;
        color: #fff;
        text-align: center;
        width: 60%;
        margin: 35px auto 45px;
      }
    }

    .view-button {
      background: #fbfbfe;
      color: #000;
      width: 270px;
    }
  }
}
