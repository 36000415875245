@media screen and (min-width: 501px) and (max-width: 560px) {
  .application-page .features .features-box .feature-item.item-five {
    min-height: 675px;
  }
}

@media screen and (min-width: 561px) and (max-width: 620px) {
  .application-page .features .features-box .feature-item.item-five {
    min-height: 730px;

    .image img {
      transform: scale(1.3) translate(0px, 50px);
    }
  }
}

@media screen and (min-width: 769px) and (max-width: 940px) {
  .application-page .features .features-box .feature-item.item-five .image {
    bottom: 10px;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .privacy-page .overview .overview-boxs .overview-item-box {
    min-height: 525px;
    padding: 1rem;
  }
  .privacy-page .overview .overview-boxs .overview-item-box .item-descrip p {
    width: 100%;
  }

  .privacy-page .our-products .products-boxs .product-poster h3 {
    font-size: 2.2rem;
  }
  .application-page .features .features-box .feature-item.item-five,
  .application-page .features .features-box .feature-item.item-six {
    min-height: 740px;
  }

  .privacy-page .overview .overview-title,
  .privacy-page .our-products .our-products-title,
  .privacy-page .your-control .your-control-title {
    width: 65%;
  }

  .privacy-page
    .overview
    .overview-boxs
    .overview-item-box.item-one
    .overview-item-title,
  .privacy-page
    .overview
    .overview-boxs
    .overview-item-box.item-five
    .overview-item-title {
    width: 90%;
    font-size: 1.65rem;
    margin-bottom: 40px;
  }

  .privacy-page
    .overview
    .overview-boxs
    .overview-item-box
    .overview-item-title,
  .privacy-page .your-control .control-boxs .control-box .control-box-title {
    width: 100%;
    font-size: 1.45rem;
  }

  .privacy-page .your-control .control-boxs .control-box {
    padding: 2rem;
  }
}

@media screen and (min-width: 769px) and (max-width: 895px) {
  .application-page .features .features-box .feature-item.item-one,
  .application-page .features .features-box .feature-item.item-two {
    min-height: 675px;
  }
}

@media screen and (min-width: 896px) and (max-width: 1025px) {
  .application-page .features .features-box .feature-item.item-one,
  .application-page .features .features-box .feature-item.item-two {
    min-height: 700px;
  }

  .application-page .features .features-box .feature-item.item-three,
  .application-page .features .features-box .feature-item.item-four {
    min-height: 660px;
  }
}

@media screen and (min-width: 769px) and (max-width: 871px) {
  .todo-list-page .overview .video-container .overview-title h2.title,
  .application-page .overview .video-container .overview-title h2.title {
    width: 63% !important;
  }

  .todo-list-page .overview .video-container .overview-title p.subtitle,
  .application-page .overview .video-container .overview-title p.subtitle {
    width: 93% !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 1215px) {
  .todo-list-page .overview .video-container .overview-title h2.title {
    width: 54%;
  }

  .application-page .overview .video-container .overview-title h2.title {
    width: 49%;
  }

  .todo-list-page .overview .video-container .overview-title p.subtitle,
  .application-page .overview .video-container .overview-title p.subtitle {
    width: 85%;
  }

  .todo-list-page .features .features-box .feature-item .item-content {
    width: 100% !important;
  }
  .application-page .features .features-box .feature-item {
    padding: 2rem;
  }
  .application-page .features .features-box .feature-item .item-content {
    width: 100%;
  }
  .application-page .features .features-box .feature-item h4 {
    width: 100%;
  }

  .application-page .features .feature-item.item-five .item-header,
  .application-page .features .feature-item.item-six .item-header {
    width: 70%;
  }
}

@media screen and (min-width: 1023px) and (max-width: 1407px) {
  .application-page .features .features-box .feature-item.item-six .image {
    bottom: 80%;
  }
  .application-page .features .features-box .feature-item.item-five,
  .application-page .features .features-box .feature-item.item-six {
    min-height: 785px;
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .application-page .features .features-box .feature-item {
    padding: 2.5rem;
  }
  .application-page .features .features-box .feature-item .item-content {
    width: 85%;
  }
}

@media (min-width: 1407px) {
  .application-page .overview .video-container .overview-title,
  .todo-list-page .overview .video-container .overview-title {
    h2 {
      font-size: 3rem !important;
    }
  }
  .application-page .features .features-box .feature-item.item-five .image {
    bottom: auto;
  }
  .application-page .features .features-box .feature-item.item-one,
  .application-page .features .features-box .feature-item.item-two {
    min-height: 735px;
  }
  .application-page .features .features-box .feature-item.item-three,
  .application-page .features .features-box .feature-item.item-four {
    min-height: 700px;
  }

  .application-page .features .features-box .feature-item.item-five,
  .application-page .features .features-box .feature-item.item-six {
    min-height: 835px;
  }
}
