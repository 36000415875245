.todo-list-page {
  .home {
    position: relative;
    background: url('../img/background-2.png') no-repeat center center;
    background-size: cover;
    min-height: 85vh;

    .hero-body {
      display: flex;
      align-items: center;
    }
    .home-content {
      h1.title {
        font-size: 4.5rem;
        color: $white;
        font-weight: bold;
        text-align: center;
        margin-bottom: 15px;
        margin-top: 30px;
      }

      h2.subtitle {
        color: $white;
        text-align: center;
        font-weight: 500;
        font-size: 2.3rem;
        margin-bottom: 25px;
      }
    }
  }

  .overview {
    position: relative;
    padding: 4.25rem 1.5rem 3rem;
    overflow: hidden;
    margin-bottom: 1.5rem;
    .is-overlay {
      height: 430px;
      width: 100%;
      background: transparent;
      bottom: 0;
      left: 0;
      position: absolute;
      top: auto;
      background-image: linear-gradient(to top, #141414, 86%, transparent);
      z-index: 2;
      // background: url('../img/shadow.png') no-repeat bottom center;
      background-size: cover;
      border-radius: 0 0 18px 18px;
    }
    .video-container {
      position: relative;

      #todo-video {
        margin: auto;
        display: block;
        border: none;
        outline: none;
        pointer-events: none;
      }

      .overview-title {
        z-index: 4;
        position: relative;
        top: -20px;
        margin: auto;
        h2.title {
          color: #fff;
          font-weight: bold;
          text-align: center;
          font-size: 2.75rem;
          opacity: 0;
          margin: 0 auto 35px;
          width: 40%;
        }

        p.subtitle {
          font-size: 1.35rem;
          line-height: 1.5;
          color: #fff;
          text-align: center;
          margin: auto;
          width: 64%;
        }
      }
    }
  }

  .main-feature {
    border-radius: 18px;
    background-color: #141414;
    .feature-box {
      h2 {
        color: #fff;
        font-weight: bold;
        text-align: center;
        font-size: 2.75rem;
        margin-bottom: 50px;
      }

      .features-list {
        width: 65%;
        margin: auto;
        ul {
          li:not(:last-child) {
            margin-bottom: 40px;
          }

          h5 {
            font-size: 1.55rem;
            color: #028baa;
            margin-bottom: 10px;
          }

          p {
            font-size: 1.23rem;
            color: $white;
          }
        }
      }
    }
  }

  .features {
    padding: 1.65rem 1.5rem 3rem;
    .features-box {
      .feature-item {
        min-height: 720px;

        .item-content {
          width: 85%;
          margin: auto;
        }

        background-color: #141414;
        border-radius: 18px;
        padding: 4rem;
        h4 {
          text-align: center;
          color: #fff;
          font-weight: bold;
          margin: 0px auto 25px;
          font-size: 1.75rem;
        }
        .image {
          max-width: 275px;
          margin: 0 auto 15px;
        }

        h6 {
          color: #fff;
          text-align: center;
          margin: auto;
          font-size: 1.25rem;
        }
        p.descrip {
          color: #fff;
          font-size: 1.12rem;
        }
      }
    }

    .feature-item.item-two,
    .feature-item.item-three {
      overflow: hidden;
      position: relative;

      .item-header {
        position: absolute;
        bottom: 50px;
        z-index: 5;
        width: 60%;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    .feature-item.item-one,
    .feature-item.item-two {
      min-height: 720px;
    }

    .feature-item.item-one {
      .image {
        max-width: 335px;
        margin: 35px auto 37px;
      }
    }

    .feature-item.item-two {
      .image {
        max-width: 330px;
        img {
          transform: scale(1.2) translateY(20px);
        }
      }
    }

    .feature-item.item-three {
      .image {
        max-width: 100%;
        width: 100%;
        position: relative;
        img {
          max-width: 100%;
          transform: scale(1.35) translate(14px, 45px);
        }
      }
    }

    .feature-item.item-two,
    .feature-item.item-four {
      h6 {
        color: #188b98;
        text-align: left;
        margin: 0 0 15px 0;
        width: auto;
        font-size: 1.5rem;
      }
    }
  }

  .feature-item.item-four {
    .item-content {
      display: flex;
      flex-direction: column;
    }
    h6 {
      margin-top: 35px !important;
    }
  }

  .invite-section {
    padding: 3.5rem 1.5rem 5rem;

    h2 {
      color: #fff;
      font-weight: bold;
      text-align: center;
      font-size: 3.35rem;
      margin-bottom: 10px;
    }
    h3 {
      font-size: 1.5rem;
      line-height: 1.5;
      color: #fff;
      text-align: center;
      width: 60%;
      margin: 10px auto 35px;
    }

    .buttons {
      .button {
        width: 220px;
      }
      .invite-button {
        background: #188b98;
        color: #fff;

        border: none;
      }

      .support-button {
        color: #fff;
        background: transparent;
        border: 3px solid #188b98;
      }
    }
  }
  .our-commitment {
    background-color: #1f1436;
    .image {
      max-width: 150px;
      margin: 0 auto 35px;
    }

    h2 {
      font-size: 2.75rem;
      color: $white;
      font-weight: bold;
      text-align: center;
      margin-bottom: 35px;
    }
  }

  .notice {
    padding: 1rem 1.5rem;
    text-align: center;
    p {
      color: $white;
      width: 60%;
      margin: auto;
      font-size: 0.85rem;
      color: #8e8e8e;
    }
    a {
      color: #447ccd;
    }
  }
}
