body {
  font-family: "Azo Sans", "Roboto", sans-serif;
  font-size: 16px;
  color: #333333;
  background: #010101;
  overflow-x: hidden;
}

.section-links {
  text-align: center;
  font-size: 1.25rem;
  margin-bottom: 35px;
}

.section-links a {
  display: inline-flex;
  align-items: baseline;
  color: #ffffff;
  font-weight: 500;
}

.section-links a:hover {
  color: #ffffff;
}

.section-links a .chevron-right {
  margin-top: 2px;
  margin-left: 10px;
  color: #ffffff;
}

.section-links a:not(:last-child) {
  margin-right: 30px;
}

.links {
  text-align: center;
  font-size: 1.25rem;
  margin-bottom: 35px;
}

.links a {
  display: inline-flex;
  color: #ffffff;
  font-weight: 500;
}

.links a:hover {
  color: #ffffff;
}

.links a .chevron-right {
  margin-top: 2px;
  margin-left: 10px;
  color: #ffffff;
}

.links a:not(:last-child) {
  margin-right: 30px;
}

video {
  display: block;
  z-index: 9;
}

.fadeIn {
  opacity: 0;
  animation: fadeInUp 1s ease-in-out 0s forwards;
  animation-delay: 0.25s;
}

.fadeIn_fast {
  opacity: 0;
  animation: fadeInUp 0.6s ease-in-out 0s forwards;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}

.navbar {
  background-color: #373e4d;
}

.navbar .navbar-start {
  margin: auto;
}

.navbar .navbar-start .navbar-item:not(:last-child) {
  margin-right: 25px;
}

.navbar .navbar-burger {
  color: #ffffff;
}

.navbar .navbar-burger:hover {
  background-color: none;
  color: #ffffff;
}

.navbar .navbar-item {
  color: #ffffff;
  font-size: 1.05rem;
  font-weight: 500;
}

.navbar .navbar-item:hover {
  background: none;
  color: #ffffff;
}

.navbar .navbar-end {
  margin-left: 0;
}

.navbar .navbar-end .navbar-item {
  font-weight: bold;
  font-size: 1.15rem;
}

@media (max-width: 1023px) {
  .navbar .navbar-brand {
    background-color: #373e4d;
  }
  .navbar .navbar-menu {
    display: block;
    opacity: 0;
    position: absolute;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    transition: all 0.4s ease-in-out;
    pointer-events: none;
    background-color: #373e4d;
    z-index: -1;
  }
  .navbar .navbar-menu.is-active {
    background-color: #373e4d;
    opacity: 1;
    transform: none;
    pointer-events: auto;
  }
  .navbar .navbar-item {
    color: #ffffff;
  }
  .navbar .navbar-item:hover {
    color: #ffffff;
  }
}

.sub-nav {
  border-bottom: 1px solid rgba(255, 255, 255, 0.49);
  background: rgba(1, 1, 1, 0.85) !important;
  z-index: 28;
}

.sub-nav .navbar-brand .navbar-item {
  font-size: 1.35rem;
}

.sub-nav .navbar-end {
  margin-left: auto;
}

.sub-nav .navbar-end .navbar-item {
  background: none;
  font-size: 1.05rem;
  font-weight: 500;
}

@media (max-width: 1023px) {
  .sub-nav .navbar-brand {
    background-color: rgba(1, 1, 1, 0.85);
  }
  .sub-nav .navbar-menu.is-active {
    background-color: #010101;
  }
}

.navbar.has-shadow {
  box-shadow: 0 0px 0 0 whitesmoke;
}

a.navbar-item:focus,
a.navbar-item:focus-within,
a.navbar-item:hover,
a.navbar-item.is-active,
.navbar-link:focus,
.navbar-link:focus-within,
.navbar-link:hover,
.navbar-link.is-active {
  background: none;
  color: #ffffff;
}

.privacy-page .home {
  position: relative;
  background: url("../img/background-1.png") no-repeat center center;
  background-size: cover;
  min-height: 85vh;
}

.privacy-page .home .hero-body {
  align-items: center;
  display: flex;
}

.privacy-page .home .home-content {
  width: 66%;
}

.privacy-page .home .home-content .is-flex {
  margin-bottom: 35px;
  align-items: flex-end;
}

.privacy-page .home .home-content .is-flex .image {
  max-width: 120px;
  margin-right: 25px;
}

.privacy-page .home .home-content .is-flex h1.title {
  font-size: 3rem;
  font-weight: 500;
  color: #fff;
}

.privacy-page .home .home-content p {
  color: #fff;
  line-height: 1.75;
  font-size: 1.25rem;
}

.privacy-page .overview {
  padding: 4.5rem 1.5rem 3rem;
}

.privacy-page .overview .overview-title {
  width: 47%;
  margin: auto;
}

.privacy-page .overview .overview-title h2.title {
  color: #fff;
  font-weight: bold;
  text-align: center;
  font-size: 3rem;
}

.privacy-page .overview .overview-title p.subtitle {
  font-size: 1.5rem;
  line-height: 1.5;
  color: #fff;
  text-align: center;
  margin-bottom: 45px;
}

.privacy-page .overview .overview-boxs .overview-item-box {
  background-color: #141414;
  border-radius: 18px;
  padding: 2rem;
  min-height: 590px;
}

.privacy-page .overview .overview-boxs .overview-item-box .overview-item-title {
  text-align: center;
  color: #fff;
  font-weight: bold;
  width: 100%;
  font-size: 1.5rem;
  min-height: 100px;
  margin: auto;
}

.privacy-page .overview .overview-boxs .overview-item-box .image {
  padding: 1rem;
}

.privacy-page .overview .overview-boxs .overview-item-box .image img {
  padding: 1rem;
  max-width: 320px;
  margin: auto;
}

.privacy-page .overview .overview-boxs .overview-item-box .item-descrip h5 {
  text-align: center;
  font-weight: bold;
  color: #fff;
  font-size: 1.25rem;
}

.privacy-page .overview .overview-boxs .overview-item-box .item-descrip p {
  text-align: center;
  color: #fff;
  font-size: 1.2rem;
  width: 80%;
  margin: auto;
}

.privacy-page .overview .overview-boxs .overview-item-box.item-one,
.privacy-page .overview .overview-boxs .overview-item-box.item-five {
  min-height: auto;
}

.privacy-page .overview .overview-boxs .overview-item-box.item-one .overview-item-title,
.privacy-page .overview .overview-boxs .overview-item-box.item-five .overview-item-title {
  width: 85%;
  font-size: 2rem;
  margin: 0 auto 70px;
}

.privacy-page .overview .overview-boxs .overview-item-box.item-one .image img,
.privacy-page .overview .overview-boxs .overview-item-box.item-five .image img {
  max-width: 500px;
}

.privacy-page .overview .overview-boxs .overview-item-box.item-five .overview-item-title {
  margin: 0 auto 35px;
}

.privacy-page .overview .overview-boxs .overview-item-box.item-five .links {
  text-align: center;
  color: #ffffff;
  margin-bottom: 0;
  padding-bottom: 35px;
}

.privacy-page .overview .overview-boxs .overview-item-box.item-five .links span a {
  margin-right: 4px;
}

.privacy-page .overview .overview-boxs .overview-item-box.item-five .links a {
  color: #ffffff;
  margin-left: 4px;
}

.privacy-page .overview .overview-boxs .overview-item-box.item-five .links a strong {
  color: #6772e5;
}

.privacy-page .overview .overview-boxs .overview-item-box.item-five .links a strong:hover {
  color: #6772e5;
}

.privacy-page .overview .overview-boxs .overview-item-box.item-five .links a:not(:last-child) {
  margin-right: 7px;
}

.privacy-page .overview .overview-boxs .overview-item-box.item-five .links a:hover {
  color: #ffffff;
}

.privacy-page .our-products .our-products-title {
  width: 47%;
  margin: auto;
}

.privacy-page .our-products .our-products-title h2.title {
  color: #fff;
  font-weight: bold;
  text-align: center;
  font-size: 3rem;
}

.privacy-page .our-products .our-products-title p.subtitle {
  font-size: 1.5rem;
  line-height: 1.5;
  color: #fff;
  text-align: center;
  margin-bottom: 45px;
}

.privacy-page .our-products .products-boxs .product-item {
  margin-bottom: 35px;
}

.privacy-page .our-products .products-boxs .product-item .app-bot-details-container {
  background-image: linear-gradient(to bottom, #f15151 0%, #c9946c 100%);
  border-radius: 18px 0 0 18px;
}

.privacy-page .our-products .products-boxs .product-item .todo-list-bot-details-container {
  background-image: linear-gradient(to bottom, #4baf9e 0%, #188cab 100%);
  border-radius: 18px 0 0 18px;
}

.privacy-page .our-products .products-boxs .product-item .premium-subscriptions-details-container {
  background-image: linear-gradient(to bottom, #f5b31f 0%, #e88716 100%);
  border-radius: 18px 0 0 18px;
}

.privacy-page .our-products .products-boxs .product-item .product-details {
  padding: 2rem;
}

.privacy-page .our-products .products-boxs .product-item .product-details .image {
  max-width: 128px;
  margin: 0 auto 10px;
}

.privacy-page .our-products .products-boxs .product-item .product-details h3 {
  text-align: center;
  font-size: 2rem;
  color: #ffffff;
  margin-bottom: 40px;
}

.privacy-page .our-products .products-boxs .product-item .product-details h4 {
  color: #ffffff;
  font-weight: 500;
  font-size: 1.35rem;
  margin-bottom: 15px;
}

.privacy-page .our-products .products-boxs .product-item .product-details .privacy-accordion-faq .privacy-accordion-menu-title {
  margin-bottom: 15px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.privacy-page .our-products .products-boxs .product-item .product-details .privacy-accordion-faq .privacy-accordion-menu-title h5 {
  position: relative;
  color: #fff;
  font-size: 1.25rem;
  font-weight: normal;
}

.privacy-page .our-products .products-boxs .product-item .product-details .privacy-accordion-faq .privacy-accordion-menu-title h5 .icon {
  position: absolute;
  left: auto;
  right: 0;
}

.privacy-page .our-products .products-boxs .product-item .product-details .privacy-accordion-faq .privacy-accordion-menu-title h5 .icon i {
  transition: all 0.3s ease-in-out;
}

.privacy-page .our-products .products-boxs .product-item .product-details .privacy-accordion-faq .privacy-accordion-menu-title h5 .opened {
  transform: rotate(180deg);
}

.privacy-page .our-products .products-boxs .product-item .product-details .privacy-accordion-faq .privacy-accordion-menu-content {
  display: none;
}

.privacy-page .our-products .products-boxs .product-item .product-details .privacy-accordion-faq .privacy-accordion-menu-content p {
  color: #fff;
  font-size: 1.12rem;
  margin-top: 15px;
  margin-bottom: 30px;
}

.privacy-page .our-products .products-boxs .app-bot-poster-container {
  position: relative;
  border-radius: 0 18px 18px 0;
  background-image: linear-gradient(217deg, #f15151 0%, #ec4f4f 100%);
}

.privacy-page .our-products .products-boxs .todo-list-bot-poster-container {
  position: relative;
  border-radius: 0 18px 18px 0;
  background-image: linear-gradient(46deg, #028cab 0%, #018dad 100%);
}

.privacy-page .our-products .products-boxs .premium-subscriptions-poster-container {
  position: relative;
  border-radius: 0 18px 18px 0;
  background-image: linear-gradient(216deg, #f1a829 0%, #f0a21b 100%);
}

.privacy-page .our-products .products-boxs .product-poster {
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  flex-direction: column;
}

.privacy-page .our-products .products-boxs .product-poster h3 {
  color: #ffffff;
  text-align: center;
  font-weight: bold;
  font-size: 2.75rem;
  margin-top: 25px;
  margin-bottom: 25px;
}

.privacy-page .our-products .products-boxs .product-poster .image {
  max-width: 200px;
  position: relative;
  margin-left: auto;
}

.privacy-page .your-control {
  padding-bottom: 1rem;
}

.privacy-page .your-control .your-control-title {
  width: 47%;
  margin: auto;
}

.privacy-page .your-control .your-control-title h2.title {
  color: #fff;
  font-weight: bold;
  text-align: center;
  font-size: 3rem;
}

.privacy-page .your-control .your-control-title p.subtitle {
  font-size: 1.5rem;
  line-height: 1.5;
  color: #fff;
  text-align: center;
  margin-bottom: 45px;
}

.privacy-page .your-control .control-boxs {
  border-bottom: 1px solid rgba(255, 255, 255, 0.49);
}

.privacy-page .your-control .control-boxs .control-box {
  background-color: #141414;
  border-radius: 18px;
  padding: 2rem 3.5rem;
  min-height: 600px;
}

.privacy-page .your-control .control-boxs .control-box .control-box-title {
  text-align: center;
  color: #fff;
  font-weight: bold;
  width: 100%;
  margin: auto;
  font-size: 1.5rem;
  min-height: 100px;
}

.privacy-page .your-control .control-boxs .control-box .image {
  padding: 1rem;
}

.privacy-page .your-control .control-boxs .control-box .image img {
  padding: 1rem;
  max-width: 320px;
  margin: 0 auto 15px;
}

.privacy-page .your-control .control-boxs .control-box .item-descrip {
  color: #fff;
  text-align: center;
  font-size: 1.05rem;
}

.privacy-page .your-control .notice {
  font-size: 0.85rem;
  color: #8e8e8e;
  margin-top: 45px;
  text-align: center;
  margin-bottom: 15px;
}

.privacy-page .view-details {
  padding: 5rem 1.5rem;
}

.privacy-page .view-details .view-details-title h2.title {
  color: #fff;
  font-weight: bold;
  text-align: center;
  font-size: 3rem;
}

.privacy-page .view-details .view-details-title p.subtitle {
  font-size: 1.5rem;
  line-height: 1.5;
  color: #fff;
  text-align: center;
  width: 60%;
  margin: 35px auto 45px;
}

.privacy-page .view-details .view-button {
  background: #fbfbfe;
  color: #000;
  width: 270px;
}

.todo-list-page .home {
  position: relative;
  background: url("../img/background-2.png") no-repeat center center;
  background-size: cover;
  min-height: 85vh;
}

.todo-list-page .home .hero-body {
  display: flex;
  align-items: center;
}

.todo-list-page .home .home-content h1.title {
  font-size: 4.5rem;
  color: #ffffff;
  font-weight: bold;
  text-align: center;
  margin-bottom: 15px;
  margin-top: 30px;
}

.todo-list-page .home .home-content h2.subtitle {
  color: #ffffff;
  text-align: center;
  font-weight: 500;
  font-size: 2.3rem;
  margin-bottom: 25px;
}

.todo-list-page .overview {
  position: relative;
  padding: 4.25rem 1.5rem 3rem;
  overflow: hidden;
  margin-bottom: 1.5rem;
}

.todo-list-page .overview .is-overlay {
  height: 430px;
  width: 100%;
  background: transparent;
  bottom: 0;
  left: 0;
  position: absolute;
  top: auto;
  background-image: linear-gradient(to top, #141414, 86%, transparent);
  z-index: 2;
  background-size: cover;
  border-radius: 0 0 18px 18px;
}

.todo-list-page .overview .video-container {
  position: relative;
}

.todo-list-page .overview .video-container #todo-video {
  margin: auto;
  display: block;
  border: none;
  outline: none;
  pointer-events: none;
}

.todo-list-page .overview .video-container .overview-title {
  z-index: 4;
  position: relative;
  top: -20px;
  margin: auto;
}

.todo-list-page .overview .video-container .overview-title h2.title {
  color: #fff;
  font-weight: bold;
  text-align: center;
  font-size: 2.75rem;
  opacity: 0;
  margin: 0 auto 35px;
  width: 40%;
}

.todo-list-page .overview .video-container .overview-title p.subtitle {
  font-size: 1.35rem;
  line-height: 1.5;
  color: #fff;
  text-align: center;
  margin: auto;
  width: 64%;
}

.todo-list-page .main-feature {
  border-radius: 18px;
  background-color: #141414;
}

.todo-list-page .main-feature .feature-box h2 {
  color: #fff;
  font-weight: bold;
  text-align: center;
  font-size: 2.75rem;
  margin-bottom: 50px;
}

.todo-list-page .main-feature .feature-box .features-list {
  width: 65%;
  margin: auto;
}

.todo-list-page .main-feature .feature-box .features-list ul li:not(:last-child) {
  margin-bottom: 40px;
}

.todo-list-page .main-feature .feature-box .features-list ul h5 {
  font-size: 1.55rem;
  color: #028baa;
  margin-bottom: 10px;
}

.todo-list-page .main-feature .feature-box .features-list ul p {
  font-size: 1.23rem;
  color: #ffffff;
}

.todo-list-page .features {
  padding: 1.65rem 1.5rem 3rem;
}

.todo-list-page .features .features-box .feature-item {
  min-height: 720px;
  background-color: #141414;
  border-radius: 18px;
  padding: 4rem;
}

.todo-list-page .features .features-box .feature-item .item-content {
  width: 85%;
  margin: auto;
}

.todo-list-page .features .features-box .feature-item h4 {
  text-align: center;
  color: #fff;
  font-weight: bold;
  margin: 0px auto 25px;
  font-size: 1.75rem;
}

.todo-list-page .features .features-box .feature-item .image {
  max-width: 275px;
  margin: 0 auto 15px;
}

.todo-list-page .features .features-box .feature-item h6 {
  color: #fff;
  text-align: center;
  margin: auto;
  font-size: 1.25rem;
}

.todo-list-page .features .features-box .feature-item p.descrip {
  color: #fff;
  font-size: 1.12rem;
}

.todo-list-page .features .feature-item.item-two,
.todo-list-page .features .feature-item.item-three {
  overflow: hidden;
  position: relative;
}

.todo-list-page .features .feature-item.item-two .item-header,
.todo-list-page .features .feature-item.item-three .item-header {
  position: absolute;
  bottom: 50px;
  z-index: 5;
  width: 60%;
  left: 50%;
  transform: translateX(-50%);
}

.todo-list-page .features .feature-item.item-one,
.todo-list-page .features .feature-item.item-two {
  min-height: 720px;
}

.todo-list-page .features .feature-item.item-one .image {
  max-width: 335px;
  margin: 35px auto 37px;
}

.todo-list-page .features .feature-item.item-two .image {
  max-width: 330px;
}

.todo-list-page .features .feature-item.item-two .image img {
  transform: scale(1.2) translateY(20px);
}

.todo-list-page .features .feature-item.item-three .image {
  max-width: 100%;
  width: 100%;
  position: relative;
}

.todo-list-page .features .feature-item.item-three .image img {
  max-width: 100%;
  transform: scale(1.35) translate(14px, 45px);
}

.todo-list-page .features .feature-item.item-two h6,
.todo-list-page .features .feature-item.item-four h6 {
  color: #188b98;
  text-align: left;
  margin: 0 0 15px 0;
  width: auto;
  font-size: 1.5rem;
}

.todo-list-page .feature-item.item-four .item-content {
  display: flex;
  flex-direction: column;
}

.todo-list-page .feature-item.item-four h6 {
  margin-top: 35px !important;
}

.todo-list-page .invite-section {
  padding: 3.5rem 1.5rem 5rem;
}

.todo-list-page .invite-section h2 {
  color: #fff;
  font-weight: bold;
  text-align: center;
  font-size: 3.35rem;
  margin-bottom: 10px;
}

.todo-list-page .invite-section h3 {
  font-size: 1.5rem;
  line-height: 1.5;
  color: #fff;
  text-align: center;
  width: 60%;
  margin: 10px auto 35px;
}

.todo-list-page .invite-section .buttons .button {
  width: 220px;
}

.todo-list-page .invite-section .buttons .invite-button {
  background: #188b98;
  color: #fff;
  border: none;
}

.todo-list-page .invite-section .buttons .support-button {
  color: #fff;
  background: transparent;
  border: 3px solid #188b98;
}

.todo-list-page .our-commitment {
  background-color: #1f1436;
}

.todo-list-page .our-commitment .image {
  max-width: 150px;
  margin: 0 auto 35px;
}

.todo-list-page .our-commitment h2 {
  font-size: 2.75rem;
  color: #ffffff;
  font-weight: bold;
  text-align: center;
  margin-bottom: 35px;
}

.todo-list-page .notice {
  padding: 1rem 1.5rem;
  text-align: center;
}

.todo-list-page .notice p {
  color: #ffffff;
  width: 60%;
  margin: auto;
  font-size: 0.85rem;
  color: #8e8e8e;
}

.todo-list-page .notice a {
  color: #447ccd;
}

.application-page .home {
  position: relative;
  background: url("../img/background-3.png") no-repeat center center;
  background-size: cover;
  min-height: 85vh;
}

.application-page .home .hero-body {
  display: flex;
  align-items: center;
}

.application-page .home .home-content h1.title {
  font-size: 4.5rem;
  color: #ffffff;
  font-weight: bold;
  text-align: center;
  margin-bottom: 15px;
  margin-top: 30px;
}

.application-page .home .home-content h2.subtitle {
  color: #ffffff;
  text-align: center;
  font-weight: 500;
  font-size: 2.3rem;
  margin-bottom: 25px;
}

.application-page .invite-section {
  padding: 3.5rem 1.5rem 5rem;
}

.application-page .invite-section h2 {
  color: #fff;
  font-weight: bold;
  text-align: center;
  font-size: 3.35rem;
  margin-bottom: 10px;
}

.application-page .invite-section h3 {
  font-size: 1.5rem;
  line-height: 1.5;
  color: #fff;
  text-align: center;
  width: 60%;
  margin: 10px auto 35px;
}

.application-page .invite-section .buttons .button {
  width: 220px;
}

.application-page .invite-section .buttons .invite-button {
  background: #ea7373;
  color: #fff;
  border: none;
}

.application-page .invite-section .buttons .support-button {
  color: #fff;
  background: transparent;
  border: 3px solid #ea7373;
}

.application-page .overview {
  position: relative;
  padding: 4.25rem 1.5rem 3rem;
  overflow: hidden;
  margin-bottom: 1.5rem;
}

.application-page .overview .is-overlay {
  height: 430px;
  width: 100%;
  background: transparent;
  bottom: 0;
  left: 0;
  position: absolute;
  top: auto;
  background-image: linear-gradient(to top, #141414, 77%, transparent);
  z-index: 2;
  background-size: cover;
  border-radius: 0 0 18px 18px;
}

.application-page .overview .video-container {
  position: relative;
}

.application-page .overview .video-container #todo-video {
  margin: auto;
  display: block;
  border: none;
  outline: none;
  pointer-events: none;
}

.application-page .overview .video-container .overview-title {
  z-index: 4;
  position: relative;
  top: -20px;
  margin: auto;
}

.application-page .overview .video-container .overview-title h2.title {
  color: #fff;
  font-weight: bold;
  text-align: center;
  font-size: 2.75rem;
  opacity: 0;
  width: 38%;
  margin: 0 auto 35px;
}

.application-page .overview .video-container .overview-title p.subtitle {
  font-size: 1.35rem;
  line-height: 1.5;
  color: #fff;
  text-align: center;
  width: 64%;
  margin: auto;
}

.application-page .main-feature {
  border-radius: 18px;
  background-color: #141414;
}

.application-page .main-feature .feature-box h2 {
  color: #fff;
  font-weight: bold;
  text-align: center;
  font-size: 2.75rem;
  margin-bottom: 50px;
}

.application-page .main-feature .feature-box .image {
  max-width: 660px;
}

.application-page .main-feature .feature-box .features-list {
  width: 65%;
  margin: auto;
}

.application-page .main-feature .feature-box .features-list ul li {
  margin-bottom: 40px;
}

.application-page .main-feature .feature-box .features-list ul h5 {
  font-size: 1.55rem;
  color: #ea7373;
  margin-bottom: 7px;
}

.application-page .main-feature .feature-box .features-list ul p {
  font-size: 1.23rem;
  color: #ffffff;
}

.application-page .features {
  padding: 1.65rem 1.5rem 3rem;
}

.application-page .features .features-box .feature-item {
  min-height: 685px;
  background-color: #141414;
  border-radius: 18px;
  padding: 4rem;
}

.application-page .features .features-box .feature-item .item-content {
  width: 85%;
  margin: auto;
}

.application-page .features .features-box .feature-item h4 {
  text-align: center;
  color: #fff;
  font-weight: bold;
  width: 85%;
  margin: 0px auto 25px;
  font-size: 1.75rem;
}

.application-page .features .features-box .feature-item .image {
  max-width: 275px;
  margin: 0 auto 15px;
}

.application-page .features .features-box .feature-item h6 {
  color: #fff;
  text-align: center;
  margin: auto;
  font-size: 1.25rem;
}

.application-page .features .features-box .feature-item p.descrip {
  color: #fff;
  font-size: 1.12rem;
}

.application-page .features .feature-item.item-one .item-content {
  display: flex;
  flex-direction: column;
}

.application-page .features .feature-item.item-two h6,
.application-page .features .feature-item.item-three h6,
.application-page .features .feature-item.item-six h6 {
  color: #ea7373;
  text-align: left;
  margin: 0 0 15px 0;
  width: auto;
  font-size: 1.5rem;
}

.application-page .features .feature-item.item-five,
.application-page .features .feature-item.item-six {
  overflow: hidden;
  position: relative;
  min-height: 585px;
}

.application-page .features .feature-item.item-five .item-header,
.application-page .features .feature-item.item-six .item-header {
  position: absolute;
  bottom: 50px;
  z-index: 5;
  width: 60%;
  left: 50%;
  transform: translateX(-50%);
}

.application-page .features .feature-item.item-five .image {
  max-width: 100%;
  width: 100%;
  position: relative;
  transform: scaleX(1.2);
}

.application-page .features .feature-item.item-five .image img {
  max-width: 100%;
  transform: scale(1.3) translate(0px, 70px);
}

.application-page .features .feature-item.item-six .image {
  max-width: 100%;
  width: 100%;
  position: relative;
  transform: scaleX(1.1);
}

.application-page .features .feature-item.item-six .image img {
  max-width: 100%;
  transform: scale(1.3) translate(14px, 45px);
}

.application-page .features .features-box .feature-item.item-two .image,
.application-page .features .features-box .feature-item.item-three .image {
  max-width: 500px;
}

.application-page .features .features-box .feature-item.item-four .image img,
.application-page .features .features-box .feature-item.item-one .image img {
  margin-top: 75px;
  margin-bottom: 75px;
}

.application-page .our-commitment {
  background-color: #1f1436;
}

.application-page .our-commitment .image {
  max-width: 150px;
  margin: 0 auto 35px;
}

.application-page .our-commitment h2 {
  font-size: 2.75rem;
  color: #ffffff;
  font-weight: bold;
  text-align: center;
  margin-bottom: 35px;
}

.application-page .notice {
  padding: 1rem 1.5rem;
  text-align: center;
}

.application-page .notice p {
  color: #ffffff;
  width: 60%;
  margin: auto;
  font-size: 0.85rem;
  color: #8e8e8e;
}

.application-page .notice a {
  color: #447ccd;
}

footer {
  background-color: #010101;
  padding: 1.5rem 1.5rem;
  border-top: 1px solid rgba(255, 255, 255, 0.49);
}

footer .reference-link {
  display: flex;
  align-items: flex-start;
  padding-bottom: 9px;
  border-bottom: 2px solid #c7c7c7;
}

footer .reference-link div {
  margin-right: 60px;
}

footer .reference-link .title {
  font-size: 1.3rem;
  color: #ffffff;
  font-weight: 500;
  margin-bottom: 7px;
  position: relative;
}

footer .reference-link .title .icon {
  display: none;
  position: absolute;
  right: 10px;
  top: 50%;
  font-size: 0.9rem;
  transform: translateY(-50%);
}

footer .reference-link .title .icon i {
  transition: all 0.3s ease-in-out;
}

footer .reference-link .title .opened {
  transform: rotate(45deg);
}

footer .reference-link ul li {
  margin-bottom: 7px;
}

footer .reference-link ul li a {
  color: #ffffff;
  font-size: 1.15rem;
}

footer .reference-link ul li a:hover {
  color: #ffffff;
}

footer .footer-btm {
  display: flex;
  margin-top: 1rem;
}

footer .footer-btm p {
  color: #ffffff;
  font-size: 1.15rem;
  margin-right: 60px;
}

footer .footer-btm .pages-links li {
  padding: 0 15px;
}

footer .footer-btm .pages-links li:not(:last-child) {
  border-right: 1px solid #8b8b90;
}

footer .footer-btm a {
  color: #ffffff;
  font-size: 1.15rem;
}

footer .footer-btm a:hover {
  color: #ffffff;
}

@media (max-width: 1239px) {
  footer .copyrights,
  footer .footer-btm a {
    font-size: 1rem !important;
  }
  footer .copyrights {
    margin-right: 15px !important;
  }
  footer .footer-btm li {
    padding: 0 8px !important;
  }
}

@media (max-width: 375px) {
  .privacy-page .home .home-content p {
    font-size: 1.12rem;
  }
  .privacy-page .overview .overview-item-box.item-one .overview-item-title,
  .privacy-page .overview .overview-item-box.item-five .overview-item-title {
    font-size: 1.5rem !important;
  }
  .privacy-page .overview .overview-item-box .overview-item-title {
    width: 100% !important;
  }
  .privacy-page .our-products .products-boxs .product-item .product-details {
    padding: 1rem;
  }
  .privacy-page .our-products .products-boxs .product-item .product-details h3 {
    font-size: 1.4rem !important;
  }
  .privacy-page .overview .overview-boxs .overview-item-box .item-descrip p {
    font-size: 1.05rem;
  }
  .privacy-page .your-control .control-boxs .control-box .control-box-title {
    width: 100%;
  }
  .todo-list-page .features .feature-item.item-three .image,
  .application-page .features .features-box .feature-item.item-six .image {
    bottom: 66%;
    top: auto;
  }
  .todo-list-page .features .features-box .feature-item,
  .application-page .features .features-box .feature-item {
    min-height: 585px;
  }
  .todo-list-page .features .feature-item.item-two .item-header,
  .todo-list-page .features .feature-item.item-three .item-header {
    width: 75%;
  }
  .todo-list-page .features .feature-item.item-three .image,
  .application-page .features .features-box .feature-item.item-six .image {
    right: -13%;
    bottom: 70% !important;
  }
  .todo-list-page .features .feature-item.item-three img,
  .application-page .features .features-box .feature-item.item-six .image img {
    right: -57% !important;
  }
  .application-page .features .features-box .feature-item.item-five {
    min-height: 475px !important;
  }
  .home h1.title {
    font-size: 2.9rem !important;
  }
  .home h2.subtitle {
    font-size: 1.5rem !important;
  }
  .our-commitment h2 {
    font-size: 2.25rem !important;
  }
  .privacy-page .home .home-content .image {
    max-width: 110px !important;
    margin-right: 12px !important;
  }
  .todo-list-page .features .feature-item.item-two .item-header {
    width: 76%;
  }
  .application-page .features .features-box .feature-item h4 {
    width: 100%;
    font-size: 1.55rem;
  }
  .application-page .features .feature-item.item-five .item-header,
  .application-page .features .feature-item.item-six .item-header {
    width: 80%;
  }
  .application-page .features .feature-item.item-five .image {
    left: -30px !important;
  }
  .section-links {
    z-index: 1;
    font-size: 1rem !important;
  }
  .section-links a:not(:last-child) {
    margin-right: 5px;
  }
  .our-commitment h2 {
    font-size: 2.25rem !important;
  }
  footer .reference-link .title,
  footer .reference-link .icon {
    font-size: 1rem !important;
  }
  footer .reference-link ul li a {
    font-size: 0.95rem !important;
  }
  footer .footer-btm a,
  footer .footer-btm .copyrights {
    font-size: 0.9rem !important;
  }
}

@media (max-width: 500px) {
  .privacy-page br {
    display: none;
  }
  .privacy-page .overview .overview-item-box.item-two br {
    display: block;
  }
  .privacy-page .overview .overview-boxs .overview-item-box.item-one .overview-item-title,
  .privacy-page .overview .overview-boxs .overview-item-box.item-five .overview-item-title {
    width: 95%;
    font-size: 1.85rem;
    margin: 0 auto 25px;
  }
  .privacy-page .overview .overview-item-box.item-five .links {
    font-size: 1rem;
    margin-bottom: 0 !important;
  }
  .privacy-page .overview .overview-title h2,
  .privacy-page .our-products .our-products-title h2.title,
  .privacy-page .your-control .your-control-title h2.title,
  .privacy-page .view-details .view-details-title h2.title,
  .todo-list-page .overview .video-container .overview-title h2.title,
  .todo-list-page .invite-section h2 {
    font-size: 2.35rem !important;
  }
  .privacy-page .overview .overview-title p.subtitle,
  .privacy-page .our-products .our-products-title p.subtitle,
  .privacy-page .your-control .your-control-title p.subtitle,
  .privacy-page .view-details .view-details-title p.subtitle,
  .todo-list-page .overview .video-container .overview-title p.subtitle {
    font-size: 1.25rem;
  }
  .overview-boxs .overview-item-box,
  .control-boxs .control-box,
  .todo-list-page .features .features-box .feature-item,
  .application-page .features .features-box .feature-item {
    padding: 2rem 1rem !important;
  }
  .todo-list-page .home .home-content h1.title,
  .application-page .home .home-content h1.title {
    font-size: 3.55rem;
  }
  .application-page .features .features-box .feature-item h4,
  .todo-list-page .features .features-box .feature-item h4 {
    width: 100%;
  }
  .application-page .features .feature-item.item-five .image img {
    transform: scale(1.3) translate(0px, 40px);
  }
  .application-page .features .feature-item.item-five .item-header {
    bottom: 30px;
  }
  .application-page .overview .video-container .overview-title {
    top: 0;
  }
  .application-page .features .feature-item.item-five .item-header,
  .application-page .features .feature-item.item-six .item-header {
    width: 80%;
  }
  .application-page .main-feature .feature-box .features-list,
  .todo-list-page .main-feature .feature-box .features-list {
    width: 85%;
  }
  .todo-list-page .main-feature .feature-box h2 {
    font-size: 2rem;
  }
  .todo-list-page .features .features-box .feature-item .item-content,
  .application-page .features .features-box .feature-item .item-content {
    width: 85%;
  }
  .todo-list-page .invite-section h3,
  .application-page .invite-section h3 {
    width: 100%;
    font-size: 1.25rem;
  }
  .todo-list-page .features .feature-item.item-two .item-header,
  .todo-list-page .features .feature-item.item-three .item-header {
    width: 77%;
  }
  .todo-list-page .features .feature-item.item-three .image,
  .application-page .features .features-box .feature-item.item-six .image {
    bottom: 66%;
    left: 0;
    right: 0;
  }
  .todo-list-page .features .feature-item.item-three img,
  .application-page .features .features-box .feature-item.item-six .image img {
    right: -60%;
  }
  .application-page .overview .video-container .overview-title {
    width: 100% !important;
  }
  .application-page .features .features-box .feature-item.item-five {
    min-height: 620px;
  }
  .application-page .features .features-box .feature-item.item-six {
    min-height: 750px;
  }
  .section-links {
    z-index: 1;
    font-size: 1rem;
  }
  .section-links a:not(:last-child) {
    margin-right: 5px;
  }
  footer .reference-link .title,
  footer .reference-link .icon {
    font-size: 1.05rem;
  }
  footer .reference-link ul li a {
    font-size: 1rem;
  }
  footer .footer-btm a,
  footer .footer-btm .copyrights {
    font-size: 0.9rem !important;
  }
}

@media (max-width: 768px) {
  .privacy-page .home .home-content,
  .privacy-page .overview .overview-title,
  .privacy-page .overview .overview-boxs .overview-item-box .item-descrip p,
  .privacy-page .our-products .our-products-title,
  .privacy-page .your-control .your-control-title,
  .privacy-page .view-details .view-details-title p.subtitle {
    width: 100%;
  }
  .privacy-page .overview .overview-boxs .overview-item-box {
    min-height: auto;
  }
  .privacy-page .overview .overview-boxs .overview-item-box .image img {
    padding: 1rem;
  }
  .privacy-page .overview-boxs .image-container {
    margin-bottom: 35px;
  }
  .privacy-page .overview-boxs .overview-item-box.item-one .overview-item-title {
    margin: 0 auto 25px !important;
  }
  .privacy-page .our-products .products-boxs .product-item .columns {
    display: flex;
    flex-direction: column-reverse;
  }
  .privacy-page .our-products .products-boxs .app-bot-poster-container,
  .privacy-page .products-boxs .todo-list-bot-poster-container,
  .privacy-page .products-boxs .premium-subscriptions-poster-container {
    display: none;
  }
  .privacy-page .product-item .app-bot-details-container,
  .privacy-page .product-item .todo-list-bot-details-container,
  .privacy-page .product-item .premium-subscriptions-details-container {
    border-radius: 18px !important;
  }
  .privacy-page .product-item .title-phone {
    margin-bottom: 0 !important;
    margin-top: 35px;
  }
  .privacy-page .our-products .products-boxs .product-poster h3 {
    margin-top: 10px;
    font-size: 2rem;
  }
  .privacy-page .our-products .products-boxs .product-poster .image {
    max-width: 180px;
    margin: auto;
  }
  .privacy-page .your-control {
    padding-top: 0;
  }
  .privacy-page .your-control .control-boxs .control-box {
    min-height: auto;
  }
  .application-page .features .features-box .feature-item.item-four .image img,
  .application-page .features .features-box .feature-item.item-one .image img {
    margin-bottom: 15px;
    margin-top: 15px;
  }
  .todo-list-page .overview .video-container .overview-title .title,
  .application-page .overview .video-container .overview-title h2.title {
    width: 85% !important;
  }
  .todo-list-page .overview .video-container .overview-title p.subtitle,
  .application-page .overview .video-container .overview-title p.subtitle {
    width: 100% !important;
  }
  .application-page .invite-section,
  .todo-list-page .invite-section {
    padding: 0rem 1.5rem 3rem;
  }
  .privacy-page .view-details {
    padding: 3rem 1.5rem;
  }
  .application-page .notice p,
  .todo-list-page .notice p {
    width: 100%;
  }
  .application-page .features .feature-item.item-five .item-header,
  .application-page .features .feature-item.item-six .item-header {
    width: 75%;
  }
  .todo-list-page .main-feature .feature-box .image {
    margin: auto;
  }
  .todo-list-page .features .features-box .feature-item.item-four {
    min-height: auto;
  }
  .todo-list-page .features .features-box .feature-item.item-one {
    padding: 4rem 1rem 1rem !important;
  }
  .application-page .main-feature .feature-box h2 {
    font-size: 1.85rem;
  }
  .application-page .features .features-box .feature-item.item-four {
    min-height: auto !important;
  }
  .todo-list-page .features .features-box .feature-item.item-four .image img,
  .application-page .features .features-box .feature-item.item-one .image img {
    margin-top: 25px;
    margin-bottom: 25px;
  }
  .application-page .features .features-box .feature-item.item-one,
  .application-page .features .features-box .feature-item.item-two,
  .application-page .features .features-box .feature-item.item-three,
  .todo-list-page .features .features-box .feature-item.item-one {
    min-height: auto !important;
  }
  .todo-list-page .features .features-box .feature-item.item-one {
    padding: 2rem !important;
  }
  .application-page .features .feature-item.item-six,
  .application-page .features .feature-item.item-five {
    min-height: 835px;
  }
  footer .reference-link {
    padding-bottom: 0;
    border: 0;
    display: block;
  }
  footer .reference-link .title {
    padding: 7px 0;
    border-bottom: 1px solid #c7c7c7;
  }
  footer .reference-link div {
    margin-right: 0;
  }
  footer .reference-link div:not(:last-child) {
    margin-bottom: 10px;
  }
  footer .reference-link ul {
    padding-left: 15px;
  }
  footer .footer-btm {
    display: flex;
    flex-direction: column-reverse;
  }
  footer .footer-btm .pages-links {
    margin-bottom: 10px;
    text-align: center;
    justify-content: center;
  }
  footer .footer-btm .copyrights {
    margin: 0;
    text-align: center;
  }
}

@media (max-width: 500px) {
  .todo-list-page .overview .video-container .overview-title .title,
  .todo-list-page .overview .video-container .overview-title p.subtitle,
  .application-page .overview .video-container .overview-title .title,
  .application-page .overview .video-container .overview-title p.subtitle {
    width: 100% !important;
  }
}

@media screen and (min-width: 501px) and (max-width: 560px) {
  .application-page .features .features-box .feature-item.item-five {
    min-height: 675px;
  }
}

@media screen and (min-width: 561px) and (max-width: 620px) {
  .application-page .features .features-box .feature-item.item-five {
    min-height: 730px;
  }
  .application-page .features .features-box .feature-item.item-five .image img {
    transform: scale(1.3) translate(0px, 50px);
  }
}

@media screen and (min-width: 769px) and (max-width: 940px) {
  .application-page .features .features-box .feature-item.item-five .image {
    bottom: 10px;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .privacy-page .overview .overview-boxs .overview-item-box {
    min-height: 525px;
    padding: 1rem;
  }
  .privacy-page .overview .overview-boxs .overview-item-box .item-descrip p {
    width: 100%;
  }
  .privacy-page .our-products .products-boxs .product-poster h3 {
    font-size: 2.2rem;
  }
  .application-page .features .features-box .feature-item.item-five,
  .application-page .features .features-box .feature-item.item-six {
    min-height: 740px;
  }
  .privacy-page .overview .overview-title,
  .privacy-page .our-products .our-products-title,
  .privacy-page .your-control .your-control-title {
    width: 65%;
  }
  .privacy-page
.overview
.overview-boxs
.overview-item-box.item-one
.overview-item-title,
  .privacy-page
.overview
.overview-boxs
.overview-item-box.item-five
.overview-item-title {
    width: 90%;
    font-size: 1.65rem;
    margin-bottom: 40px;
  }
  .privacy-page
.overview
.overview-boxs
.overview-item-box
.overview-item-title,
  .privacy-page .your-control .control-boxs .control-box .control-box-title {
    width: 100%;
    font-size: 1.45rem;
  }
  .privacy-page .your-control .control-boxs .control-box {
    padding: 2rem;
  }
}

@media screen and (min-width: 769px) and (max-width: 895px) {
  .application-page .features .features-box .feature-item.item-one,
  .application-page .features .features-box .feature-item.item-two {
    min-height: 675px;
  }
}

@media screen and (min-width: 896px) and (max-width: 1025px) {
  .application-page .features .features-box .feature-item.item-one,
  .application-page .features .features-box .feature-item.item-two {
    min-height: 700px;
  }
  .application-page .features .features-box .feature-item.item-three,
  .application-page .features .features-box .feature-item.item-four {
    min-height: 660px;
  }
}

@media screen and (min-width: 769px) and (max-width: 871px) {
  .todo-list-page .overview .video-container .overview-title h2.title,
  .application-page .overview .video-container .overview-title h2.title {
    width: 63% !important;
  }
  .todo-list-page .overview .video-container .overview-title p.subtitle,
  .application-page .overview .video-container .overview-title p.subtitle {
    width: 93% !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 1215px) {
  .todo-list-page .overview .video-container .overview-title h2.title {
    width: 54%;
  }
  .application-page .overview .video-container .overview-title h2.title {
    width: 49%;
  }
  .todo-list-page .overview .video-container .overview-title p.subtitle,
  .application-page .overview .video-container .overview-title p.subtitle {
    width: 85%;
  }
  .todo-list-page .features .features-box .feature-item .item-content {
    width: 100% !important;
  }
  .application-page .features .features-box .feature-item {
    padding: 2rem;
  }
  .application-page .features .features-box .feature-item .item-content {
    width: 100%;
  }
  .application-page .features .features-box .feature-item h4 {
    width: 100%;
  }
  .application-page .features .feature-item.item-five .item-header,
  .application-page .features .feature-item.item-six .item-header {
    width: 70%;
  }
}

@media screen and (min-width: 1023px) and (max-width: 1407px) {
  .application-page .features .features-box .feature-item.item-six .image {
    bottom: 80%;
  }
  .application-page .features .features-box .feature-item.item-five,
  .application-page .features .features-box .feature-item.item-six {
    min-height: 785px;
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .application-page .features .features-box .feature-item {
    padding: 2.5rem;
  }
  .application-page .features .features-box .feature-item .item-content {
    width: 85%;
  }
}

@media (min-width: 1407px) {
  .application-page .overview .video-container .overview-title h2,
  .todo-list-page .overview .video-container .overview-title h2 {
    font-size: 3rem !important;
  }
  .application-page .features .features-box .feature-item.item-five .image {
    bottom: auto;
  }
  .application-page .features .features-box .feature-item.item-one,
  .application-page .features .features-box .feature-item.item-two {
    min-height: 735px;
  }
  .application-page .features .features-box .feature-item.item-three,
  .application-page .features .features-box .feature-item.item-four {
    min-height: 700px;
  }
  .application-page .features .features-box .feature-item.item-five,
  .application-page .features .features-box .feature-item.item-six {
    min-height: 835px;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1800px) {
  .privacy-page .overview .overview-title h2.title,
  .privacy-page .our-products .our-products-title h2.title,
  .privacy-page .your-control .your-control-title h2.title {
    font-size: 2.65rem;
  }
  .privacy-page .overview .overview-title p.subtitle,
  .privacy-page .our-products .our-products-title p.subtitle,
  .privacy-page .your-control .your-control-title p.subtitle {
    font-size: 1.35rem;
  }
  .privacy-page
.overview
.overview-boxs
.overview-item-box.item-one
.overview-item-title,
  .privacy-page
.overview
.overview-boxs
.overview-item-box.item-five
.overview-item-title {
    font-size: 1.5rem;
    width: 80%;
  }
  .privacy-page .overview .overview-boxs .overview-item-box .item-descrip h5 {
    font-size: 1.15rem;
  }
  .privacy-page .overview .overview-boxs .overview-item-box .item-descrip p {
    font-size: 1rem;
  }
  .privacy-page
.overview
.overview-boxs
.overview-item-box
.overview-item-title {
    font-size: 1.3rem;
  }
  .privacy-page .overview .overview-boxs .overview-item-box .image img {
    max-width: 300px;
  }
  .privacy-page .overview .overview-boxs .overview-item-box.item-one .image img,
  .privacy-page
.overview
.overview-boxs
.overview-item-box.item-five
.image
img {
    max-width: 430px;
  }
  .privacy-page .overview .overview-boxs .overview-item-box {
    min-height: 560px;
  }
  .todo-list-page .overview .video-container .overview-title h2.title {
    font-size: 2.65rem !important;
    width: 37%;
  }
  .todo-list-page .features .columns,
  .application-page .features .columns {
    width: 95%;
    margin: auto;
  }
  .navbar .navbar-end .navbar-item {
    font-size: 0.9rem !important;
  }
  .our-commitment h2 {
    font-size: 2.15rem;
  }
  .links {
    font-size: 1.05rem;
  }
  .our-commitment h2 {
    font-size: 2.15rem !important;
  }
  footer .reference-link .title {
    font-size: 1.15rem;
  }
  footer .reference-link ul li a {
    font-size: 1rem;
  }
  footer .footer-btm p {
    font-size: 1rem;
  }
  footer .footer-btm a {
    font-size: 1rem;
  }
}

@media (min-width: 1216px) {
  .todo-list-page .features .features-box .feature-item.item-four .image img {
    margin-top: 45px;
    margin-bottom: 45px;
  }
}

@media (min-width: 1900px) {
  .todo-list-page .features .features-box .feature-item.item-three,
  .todo-list-page .features .features-box .feature-item.item-four {
    min-height: 725px !important;
  }
  .application-page .features .features-box .feature-item.item-one,
  .application-page .features .features-box .feature-item.item-two {
    min-height: 765px !important;
  }
  .todo-list-page .features .features-box .feature-item.item-four .image img {
    margin-top: 70px;
    margin-bottom: 50px;
  }
}
