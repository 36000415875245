@media (min-width: 1216px) {
  .todo-list-page .features .features-box .feature-item.item-four .image img {
    margin-top: 45px;
    margin-bottom: 45px;
  }
}

@media (min-width: 1900px) {
  .todo-list-page .features .features-box .feature-item.item-three,
  .todo-list-page .features .features-box .feature-item.item-four {
    min-height: 725px !important;
  }

  .application-page .features .features-box .feature-item.item-one,
  .application-page .features .features-box .feature-item.item-two {
    min-height: 765px !important;
  }

  .todo-list-page .features .features-box .feature-item.item-four .image img {
    margin-top: 70px;
    margin-bottom: 50px;
  }
}
