// FOOTER
footer {
  background-color: #010101;
  padding: 1.5rem 1.5rem;
  border-top: 1px solid rgba($white, 0.49);

  .reference-link {
    display: flex;
    align-items: flex-start;
    padding-bottom: 9px;
    border-bottom: 2px solid #c7c7c7;
    div {
      margin-right: 60px;
    }

    .title {
      font-size: 1.3rem;
      color: #ffffff;
      font-weight: 500;
      margin-bottom: 7px;
      position: relative;

      .icon {
        display: none;
        position: absolute;
        right: 10px;
        top: 50%;
        font-size: 0.9rem;
        transform: translateY(-50%);

        i {
          transition: all 0.3s ease-in-out;
        }
      }

      .opened {
        transform: rotate(45deg);
      }
    }

    ul {
      li {
        margin-bottom: 7px;
        a {
          color: #ffffff;
          font-size: 1.15rem;

          &:hover {
            color: #ffffff;
          }
        }
      }
    }
  }

  .footer-btm {
    display: flex;
    margin-top: 1rem;

    p {
      color: #ffffff;
      font-size: 1.15rem;
      margin-right: 60px;
    }

    .pages-links {
      li {
        padding: 0 15px;
      }

      li:not(:last-child) {
        border-right: 1px solid #8b8b90;
      }
    }

    a {
      color: #ffffff;
      font-size: 1.15rem;

      &:hover {
        color: #ffffff;
      }
    }
  }
}
