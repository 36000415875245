// GENERAL STYLE

body {
  font-family: $mainFont;
  font-size: $mainFontSize;
  color: $LightBlack;
  background: #010101;
  overflow-x: hidden;
}

.section-links {
  text-align: center;
  font-size: 1.25rem;
  margin-bottom: 35px;
  a {
    display: inline-flex;
    align-items: baseline;
    color: $white;
    font-weight: 500;

    &:hover {
      color: $white;
    }

    .chevron-right {
      margin-top: 2px;
      margin-left: 10px;
      color: $white;
    }
  }

  a:not(:last-child) {
    margin-right: 30px;
  }
}

.links {
  text-align: center;
  font-size: 1.25rem;
  margin-bottom: 35px;
  a {
    display: inline-flex;
    color: $white;
    font-weight: 500;

    &:hover {
      color: $white;
    }

    .chevron-right {
      margin-top: 2px;
      margin-left: 10px;
      color: $white;
    }
  }

  a:not(:last-child) {
    margin-right: 30px;
  }
}

video {
  display: block;
  z-index: 9;
}

.fadeIn {
  opacity: 0;
  animation: fadeInUp 1s ease-in-out 0s forwards;
  animation-delay: 0.25s;
}

.fadeIn_fast {
  opacity: 0;
  animation: fadeInUp 0.6s ease-in-out 0s forwards;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}
