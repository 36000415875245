// LAPTOPS DIFFERENT SIZES
@media only screen and (min-width: 1024px) and (max-width: 1800px) {
  // ZOOM OUT THE CONTENT SIZE

  // PRIVACY PAGE
  .privacy-page .overview .overview-title h2.title,
  .privacy-page .our-products .our-products-title h2.title,
  .privacy-page .your-control .your-control-title h2.title {
    font-size: 2.65rem;
  }

  .privacy-page .overview .overview-title p.subtitle,
  .privacy-page .our-products .our-products-title p.subtitle,
  .privacy-page .your-control .your-control-title p.subtitle {
    font-size: 1.35rem;
  }

  .privacy-page
    .overview
    .overview-boxs
    .overview-item-box.item-one
    .overview-item-title,
  .privacy-page
    .overview
    .overview-boxs
    .overview-item-box.item-five
    .overview-item-title {
    font-size: 1.5rem;
    width: 80%;
  }

  .privacy-page .overview .overview-boxs .overview-item-box .item-descrip h5 {
    font-size: 1.15rem;
  }

  .privacy-page .overview .overview-boxs .overview-item-box .item-descrip p {
    font-size: 1rem;
  }

  .privacy-page
    .overview
    .overview-boxs
    .overview-item-box
    .overview-item-title {
    font-size: 1.3rem;
  }

  .privacy-page .overview .overview-boxs .overview-item-box .image img {
    max-width: 300px;
  }

  .privacy-page .overview .overview-boxs .overview-item-box.item-one .image img,
  .privacy-page
    .overview
    .overview-boxs
    .overview-item-box.item-five
    .image
    img {
    max-width: 430px;
  }

  .privacy-page .overview .overview-boxs .overview-item-box {
    min-height: 560px;
  }

  // TODO LIST PAGE
  .todo-list-page .overview .video-container .overview-title h2.title {
    font-size: 2.65rem !important;
    width: 37%;
  }

  .todo-list-page .features .columns,
  .application-page .features .columns {
    width: 95%;
    margin: auto;
  }

  .navbar .navbar-end .navbar-item {
    font-size: 0.9rem !important;
  }

  .our-commitment h2 {
    font-size: 2.15rem;
  }

  .links {
    font-size: 1.05rem;
  }

  .our-commitment h2 {
    font-size: 2.15rem !important;
  }

  footer .reference-link .title {
    font-size: 1.15rem;
  }
  footer .reference-link ul li a {
    font-size: 1rem;
  }

  footer .footer-btm p {
    font-size: 1rem;
  }

  footer .footer-btm a {
    font-size: 1rem;
  }
}
