// NAVBAR
.navbar {
  background-color: $BrightGray;

  .navbar-start {
    margin: auto;

    .navbar-item:not(:last-child) {
      margin-right: 25px;
    }
  }

  .navbar-burger {
    color: $white;

    &:hover {
      background-color: none;
      color: $white;
    }
  }

  .navbar-item {
    color: $white;
    font-size: 1.05rem;
    font-weight: 500;
    &:hover {
      background: none;
      color: $white;
    }
  }

  .navbar-end {
    margin-left: 0;
    .navbar-item {
      font-weight: bold;
      font-size: 1.15rem;
    }
  }

  @media (max-width: 1023px) {
    .navbar-brand {
      background-color: #373e4d;
    }

    .navbar-menu {
      display: block;
      opacity: 0;
      position: absolute;
      left: 0;
      right: 0;
      transform: translateY(-50%);
      transition: all 0.4s ease-in-out;
      pointer-events: none;
      background-color: #373e4d;
      z-index: -1;
    }

    .navbar-menu.is-active {
      background-color: #373e4d;
      opacity: 1;
      transform: none;
      pointer-events: auto;
    }

    .navbar-item {
      color: $white;

      &:hover {
        color: $white;
      }
    }
  }
}

.sub-nav {
  border-bottom: 1px solid rgba($white, 0.49);
  background: rgba(#010101, 0.85) !important;
  z-index: 28;
  .navbar-brand {
    .navbar-item {
      font-size: 1.35rem;
    }
  }
  .navbar-end {
    margin-left: auto;

    .navbar-item {
      background: none;
      font-size: 1.05rem;
      font-weight: 500;
    }
  }

  @media (max-width: 1023px) {
    .navbar-brand {
      background-color: rgba(#010101, 0.85);
    }
    .navbar-menu.is-active {
      background-color: #010101;
    }
  }
}

.navbar.has-shadow {
  box-shadow: 0 0px 0 0 whitesmoke;
}

a.navbar-item:focus,
a.navbar-item:focus-within,
a.navbar-item:hover,
a.navbar-item.is-active,
.navbar-link:focus,
.navbar-link:focus-within,
.navbar-link:hover,
.navbar-link.is-active {
  background: none;
  color: $white;
}
